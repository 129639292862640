<template>
    <div>
        <div class="riddle-description">
            <img class="desc-image" :src="descImg" alt="" />
            <!-- <RiddleDescriptionSound :sound="sound" /> -->
            <RiddleDescriptionVideo :video="video" />
            <p>
                {{ $t('Views.Game.Obelisk.Fragment.Description') }}
            </p>
            <router-link class="btn-map" to="/game/map">
                {{ $t('Utilities.Fragments.Button') }}
            </router-link>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted } from '@vue/runtime-core'
import { useApi } from '@/lib/useApi.js'
import { useStore } from 'vuex'
import RiddleDescriptionSound from '../../../components/Game/RiddleDescriptionSound.vue'
import RiddleDescriptionVideo from '../../../components/Game/RiddleDescriptionVideo.vue'
import * as descImg from '@/assets/obelisk/background.jpg'
import * as sound from '@/assets/obelisk/Fragment/sound.mp3'
import * as video from '@/assets/obelisk/Fragment/video.mp4'

export default defineComponent({
    components: { RiddleDescriptionSound, RiddleDescriptionVideo },
    setup() {
        const store = useStore()

        onMounted(() => {
            useApi('forceSolveRiddle', {
                uuid: store.state.game.currentGame.uuid,
                currentPage: window.location.pathname,
            })
        })

        return { sound, video, descImg }
    },
})
</script>

<style scoped></style>
