<template>
    <div>
        <div
            v-if="!store.state.game.currentGame.descriptionRead"
            class="riddle-description"
        >
            <img class="desc-image" :src="descImg" alt="" />        
            <!-- <RiddleDescriptionSound :sound="sound" /> -->
            <p>
                {{ $t('Views.Game.Obelisk.SophieSchulte.Description') }}
            </p>
            <button
                @click="
                    store.commit({
                        type: 'updateDescriptionRead',
                        descriptionRead: true,
                    })
                "
            >
                {{ $t('Utilities.Riddles.DescriptionReadButton') }}
            </button>
        </div>
        <div
            v-show="store.state.game.currentGame.descriptionRead"
            class="riddle"
        >
            <img class="riddle-img" :src="imgSrc" alt="Kircheneingang" />
            <svg
                class="riddle-svg"
                viewBox="0 0 1537 2049"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    class="btn-svg"
                    @click="openPopupOben"
                    d="M376.5 653H457.5L467 631H496L516.5 635.5V653L1264 662L1267.5 812.5H1230L1226 839L1210.5 847L1189.5 849.5L1176 842L1169 830L1173.5 815H1054L970 813L967 832.5L936.5 839.5L844 837L756 861L724.5 862L713 879L688.5 882L667.5 878L654 861L622 873H585L567.5 855H522L467 799L461 857L455.5 869L449 876H434L422 867.5L420 851L427 840.5L436.5 837.5V840.5L427 846.5L422.5 857L432 868L444.5 871L452.5 868L457.5 855V800.5L387.5 802.5L376.5 731V653Z"
                    fill="#C4C4C4"
                />
                <path
                    class="btn-svg"
                    @click="openPopupUnten"
                    d="M372 1036.5H442.5L452 1017.5L471 1012.5L490 1014L493 1025V1036.5L1247.5 1044.5V1212L1197 1260.5L1087.5 1289H841.5L785.5 1260.5L761.5 1206L670.5 1204.5L662.5 1227.5L639 1234.5L615 1226L608.5 1204.5H581L573 1226L546.5 1234.5L517 1221.5L454.5 1212L442.5 1281L431.5 1289H419H405L403.5 1268L408 1250.5L414 1255.5V1281L426 1280L436 1272.5L442.5 1212H372V1036.5Z"
                    fill="#C4C4C4"
                />
            </svg>
        </div>
    </div>
</template>

<script>
import { defineComponent, inject } from '@vue/runtime-core'
import { useStore } from 'vuex'
// Normalerweise:
// Bitte beschreibende Namen für Bilder verwenden
import * as imgSrc from '@/assets/obelisk/SophieSchulte/grab.jpg'
import * as popupOben from '@/assets/obelisk/SophieSchulte/oben.jpg'
import * as popupUnten from '@/assets/obelisk/SophieSchulte/unten.jpg'
import * as descImg from '@/assets/obelisk/background.jpg'
// Pages
import * as pageAlexander from '@/assets/obelisk/pages/page_alexander.jpg'
import * as pageObelisk from '@/assets/obelisk/pages/page_obelisk.jpg'
import * as pageOtto from '@/assets/obelisk/pages/page_otto.jpg'
import * as pageSophie from '@/assets/obelisk/pages/page_sophie.jpg'
import * as pageAlexanderEN from '@/assets/obelisk/pages/page_alexander_en.jpg'
import * as pageObeliskEN from '@/assets/obelisk/pages/page_obelisk_en.jpg'
import * as pageOttoEN from '@/assets/obelisk/pages/page_otto_en.jpg'
import * as pageSophieEN from '@/assets/obelisk/pages/page_sophie_en.jpg'
import RiddleDescriptionSound from '../../../components/Game/RiddleDescriptionSound.vue'
import * as sound from '@/assets/kirche/altar/sound.mp3'

export default defineComponent({
    setup() {
        const store = useStore()
        const openPopupOben = () => {
            store.commit({
                type: 'addPopup',
                imgSrc: popupOben,
                componentType: 'Popup',
            })
        }
        const openPopupUnten = () => {
            store.commit({
                type: 'addPopup',
                imgSrc: popupUnten,
                componentType: 'Popup',
            })
        }
        const $app = inject('$app')
        const pageImageObject = {
            Alexander: {
                de: pageAlexander,
                en: pageAlexanderEN
            },
            Obelisk: {
                de: pageObelisk,
                en: pageObeliskEN
            },
            Otto: {
                de: pageOtto,
                en: pageOttoEN
            },
            Sophie: {
                de: pageSophie,
                en: pageSophieEN
            },
        }
            
        store.commit({
            type: 'updateInventory',
            inventory: [
                { name: 'Tagebuchseite', img: pageImageObject.Alexander[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Obelisk[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Otto[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Sophie[$app.$i18n.locale] },
            ],
        })
        return {
            store,
            sound,
            imgSrc,
            openPopupOben,
            openPopupUnten,
            descImg
        }
    },
    components: { RiddleDescriptionSound },
})
</script>

<style scoped>
.riddle-img,
.riddle-svg {
    aspect-ratio: 3/4;
}
</style>
