<template>
    <div>
        <div
            v-if="!store.state.game.currentGame.descriptionRead"
            class="riddle-description"
        >
            <img class="desc-image" :src="descImg" alt="" />
            <!-- <RiddleDescriptionSound :sound="sound" /> -->
            <p>
                {{ $t('Views.Game.Obelisk.Obelisk.Description') }}
            </p>
            <button
                @click="
                    store.commit({
                        type: 'updateDescriptionRead',
                        descriptionRead: true,
                    })
                "
            >
                {{ $t('Utilities.Riddles.DescriptionReadButton') }}
            </button>
        </div>
        <div
            v-show="store.state.game.currentGame.descriptionRead"
            class="riddle"
        >
            <img class="riddle-img" :src="imgSrc" alt="Kircheneingang" />
            <svg
                class="riddle-svg"
                viewBox="0 0 1537 2049"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    class="btn-svg"
                    @click="openPopupObelisk"
                    d="M616.5 284.5L701.5 199L824 265L890 1120L920.5 1147L915 1186.5L894.5 1193.5L903 1274.5L898 1312V1509L819 1547.5L712.5 1565L595 1553.5L558 1541.5L532.5 1523L539 1342.5L544 1193.5L527.5 1172.5L520 1152.5L532.5 1130L558 1120L616.5 284.5Z"
                />
            </svg>
        </div>
    </div>
</template>

<script>
import { defineComponent, inject } from '@vue/runtime-core'
import { useStore } from 'vuex'
// Normalerweise:
// Bitte beschreibende Namen für Bilder verwenden
import * as imgSrc from '@/assets/obelisk/Obelisk/Obelisk.jpg'
import * as popupObelisk from '@/assets/obelisk/Obelisk/popupObelisk.jpg'
import * as sound from '@/assets/kirche/altar/sound.mp3'
import * as descImg from '@/assets/obelisk/background.jpg'
// Pages
import * as pageAlexander from '@/assets/obelisk/pages/page_alexander.jpg'
import * as pageObelisk from '@/assets/obelisk/pages/page_obelisk.jpg'
import * as pageOtto from '@/assets/obelisk/pages/page_otto.jpg'
import * as pageSophie from '@/assets/obelisk/pages/page_sophie.jpg'
import * as pageAlexanderEN from '@/assets/obelisk/pages/page_alexander_en.jpg'
import * as pageObeliskEN from '@/assets/obelisk/pages/page_obelisk_en.jpg'
import * as pageOttoEN from '@/assets/obelisk/pages/page_otto_en.jpg'
import * as pageSophieEN from '@/assets/obelisk/pages/page_sophie_en.jpg'
import RiddleDescriptionSound from '../../../components/Game/RiddleDescriptionSound.vue'

export default defineComponent({
    setup() {
        const store = useStore()
        const openPopupObelisk = () => {
            store.commit({
                type: 'addPopup',
                imgSrc: popupObelisk,
                componentType: 'Popup',
            })
        }
        const $app = inject('$app')
        const pageImageObject = {
            Alexander: {
                de: pageAlexander,
                en: pageAlexanderEN
            },
            Obelisk: {
                de: pageObelisk,
                en: pageObeliskEN
            },
            Otto: {
                de: pageOtto,
                en: pageOttoEN
            },
            Sophie: {
                de: pageSophie,
                en: pageSophieEN
            },
        }
            
        store.commit({
            type: 'updateInventory',
            inventory: [
                { name: 'Tagebuchseite', img: pageImageObject.Alexander[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Obelisk[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Otto[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Sophie[$app.$i18n.locale] },
            ],
        })
        return {
            store,
            imgSrc,
            sound,
            descImg,
            openPopupObelisk,
        }
    },
    components: { RiddleDescriptionSound },
})
</script>

<style scoped>
.riddle-img,
.riddle-svg {
    aspect-ratio: 3/4;
}
</style>
